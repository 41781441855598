import "./Dashboard.css";
import { Card, Col, Row, Statistic } from "antd";
const App = () => (
  <Row gutter={20}>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic title="Cloud" value={158.47} precision={2} suffix="gr" />
      </Card>
    </Col>
    <Col span={4}>
      <Card>
        <Statistic title="Money" value={7800568} suffix="so'm" />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic title="Bollada" value={11.28} precision={2} suffix="gr" />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic
          title="Komplekt soni"
          value={10.33}
          precision={2}
          suffix="ta"
        />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic title="Bollada" value={11.28} precision={2} suffix="gr" />
      </Card>
    </Col>
    <Col span={4}>
      <Card bordered={false}>
        <Statistic title="Bollada" value={11.28} precision={2} suffix="gr" />
      </Card>
    </Col>
  </Row>
);
export default App;

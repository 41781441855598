import {
    Popconfirm,
    Button,
    Table,
    message,
    Input,
    InputNumber,
    Form,
    Statistic,
} from "antd";
import HeaderShop from "../../../components/HeaderShop/Header";
import { useLocation, useNavigate } from "react-router-dom";
import "./Inner.css";
import { useEffect, useState } from "react";
import { host } from "../../../config";

export default function App() {
    const [user, setUser] = useState();
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const location = useLocation();
    const userID = location.pathname.split("/")[2];
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Sana",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Oldi berdi",
            dataIndex: "summ",
            key: "summ",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "plus",
        },
    ];

    const confirm = (e) => {
        fetch(host + "/api/room", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                _id: e,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.deletedCount) {
                    navigate("/shop/clients");
                }
            })
            .catch((err) => message.error(err));
    };
    const cancel = (e) => {
        message.error("Click on No");
    };

    const fetchPost = (e) => {
        fetch(host + `/api/room/${userID}/update`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => message(err));
    };

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err));

        fetch(host + `/api/room/${userID}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setUser(res);
                setData(res.process);
            })
            .catch((err) => message(err));
    }, []);
    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <h1 style={{ padding: "20px", textAlign: "center" }}>
                    {user?.name}
                </h1>

                <Statistic
                    className="secondry-num center"
                    value={user?.summ.toFixed(2)}
                    suffix=" gr"
                />

                <Popconfirm
                    title="Mijozni o'chirish"
                    description="Bu harakatni ortga qaytarib bo'lmaydi !!!"
                    onConfirm={() => confirm(user?._id)}
                    onCancel={() => cancel()}
                    okText="O'chirish"
                    cancelText="Bekor qilish"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            </div>

            <div className="margin">
                <Form
                    onFinish={(e) => {
                        fetchPost(e);
                    }}
                    style={{
                        maxWidth: "300px",
                        margin: "30px auto",
                    }}
                >
                    <Form.Item
                        name="summ"
                        label="Vesi"
                        rules={[
                            {
                                required: true,
                                message: "Vesini yozing",
                            },
                        ]}
                    >
                        <Form.Item name="summ" noStyle>
                            <InputNumber
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="comment" label="Comment">
                        <Input placeholder="" />
                    </Form.Item>
                    <Button
                        type="primary"
                        style={{
                            width: "100%",
                        }}
                        htmlType="submit"
                    >
                        Input
                    </Button>
                </Form>
            </div>

            <div className="margin">
                <Table
                    columns={columns}
                    // scroll={{ x: 150, y: 500 }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    rowClassName={(record, index) =>
                        record.amount > 50 ? "red" : "green"
                    }
                    dataSource={user?.process}
                    pagination={false}
                />
            </div>
        </>
    );
}

import {
    Table,
    message,
    Input,
} from "antd";
import HeaderShop from "../../../../components/HeaderShop/Header";
import { useLocation } from "react-router-dom";
import "./Inner.css";
import { useEffect, useState } from "react";
import { host } from "../../../../config";

export default function App() {
    const [user, setUser] = useState();
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [tableData, setTableData] = useState(data);
    const location = useLocation();
    const userID = location.pathname.split("/")[3];
    const token = localStorage.getItem("token");

    const onInputChange = (key, index) => (e) => {
        const newData = [...data];
        newData[index][key] = e.target.value;
        setTableData(newData);
    };

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Sana",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Oldi berdi",
            dataIndex: "summ",
            key: "summ",
            render: (text, record, index) => (
                <Input disabled value={text} onChange={onInputChange("summ", index)} />
            ),
        },
        {
            title: "Comment",
            key: "plus",
            dataIndex: "comment",
            render: (text, record, index) => (
                <Input
                    disabled
                    value={text}
                    onChange={onInputChange("comment", index)}
                />
            ),
        },
        
    ];
    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err));

        fetch(host + `/api/archive/${userID}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setUser(res);
                setData(res.process);
                setTableData(res.process);
            })
            .catch((err) => message(err));
    }, []);

    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <label
                    key={"labelname"}
                    htmlFor="name"
                    style={{
                        textAlign: "right",
                    }}
                >
                    Ismi:
                </label>
                <input
                    disabled
                    className="name-hidden"
                    key={"name"}
                    type="text"
                    id="name"
                    defaultValue={user?.name}
                />
                <br />
                <label
                    htmlFor="class"
                    style={{
                        textAlign: "right",
                    }}
                    key={"labelclass"}
                >
                    Klass:
                </label>
                <input
                    className="price-hidden"
                    disabled
                    type="text"
                    id="class"
                    key={"class"}
                    defaultValue={user?.class}
                    suffix=" gr"
                />
                <br />

                <label
                    htmlFor="summ"
                    style={{
                        textAlign: "right",
                    }}
                    key={"labelsumm"}
                >
                    +/-:
                </label>
                <input
                    className="price-hidden"
                    type="text"
                    disabled
                    id="summ"
                   
                    defaultValue={user?.summ.toFixed(2)}
                    key={"summ"}
                    suffix=" gr"
                />
                <br />

                
            </div>


            <div className="margin">
                <Table
                    columns={columns}
                    // scroll={{ x: 150, y: 500 }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    rowClassName={(record, index) =>
                        record.amount > 50 ? "red" : "green"
                    }
                    dataSource={tableData}
                    pagination={false}
                />
            </div>
        </>
    );
}

import { message } from "antd";
import { useEffect, useState } from "react";
import { host } from "../../config";
import "./Print.css";
import { useLocation } from "react-router-dom";

export default function App() {
    const [data, setData] = useState();
    const token = localStorage.getItem("token");

    var arrayParam = useLocation().search.substring(1);

    if (arrayParam.includes(",")) {
        arrayParam = arrayParam.split(",");
    } else {
        arrayParam = [arrayParam];
    }

    useEffect(() => {
        fetch(host + "/api/clients/filter", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                classes: arrayParam,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                res.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                res.sort(function (a, b) {
                    var nameA = a.owner.toUpperCase();
                    var nameB = b.owner.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                setData(res);
            })
            .catch((err) => message(err));
    }, []);

    var owners = [];

    data?.forEach(function (item) {
        if (!owners.includes(item.owner)) {
            owners.push(item.owner);
        }
    });
    let tables = [];
    for (let i = 0; i < data?.length; i += 30) {
        tables.push(data?.slice(i, i + 30));
    }
    return (
        <>
            <div className="table-container">
                {tables?.map((element) => {
                    return (
                        <div className="table">
                            {/* <h3>{owner}</h3> */}
                            <table>
                                <tr>
                                    <th>Baza</th>
                                    <th>Nomi</th>
                                    <th>Ost</th>
                                    <th>GR</th>
                                    <th>=</th>
                                </tr>

                                {element?.map((el) => {
                                    // if (el.owner === owner) {
                                    return (
                                        <tr>
                                            <td>{el.owner}</td>
                                            <td>{el.name}</td>
                                            <td>{el.summ.toFixed(2)}</td>
                                            <td width={"50px"}></td>
                                            <td width={"50px"}></td>
                                        </tr>
                                    );
                                    // }
                                })}
                            </table>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

import React from "react";
import { Layout } from "antd";
import TopicMenu from "../../components/TopicMenu/TopicMenu";
import "./Template.css";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import { useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";

function App() {
    const location = useLocation();
    const Menu = <TopicMenu />;
    return (
        <>
            <NavBar menu={Menu} />
            <Layout>
                <SideBar menu={Menu} />
                <Layout.Content className="content">
                    {location.pathname === "/dashboard" ? <Dashboard /> : ""}
                    {location.pathname === "/clients" ? "" : ""}
                    {location.pathname === "/workers" ? "" : ""}
                    {location.pathname === "/money" ? "" : ""}
                    {location.pathname === "/gold" ? "" : ""}
                </Layout.Content>
            </Layout>
        </>
    );
}
export default App;

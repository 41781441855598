import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  TeamOutlined,
  LogoutOutlined,
  IdcardOutlined,
  DollarOutlined,
  GoldOutlined,
} from "@ant-design/icons";

const TopicMenu = () => {
  const location = useLocation();
  const styledTopics = [
    <Menu.Item
      key={1}
      className={location.pathname === "/dashboard" ? "selected" : ""}>
      <Link className="link-menu" to="/dashboard">
        <DashboardOutlined />
        <span>Dashboard</span>
      </Link>
    </Menu.Item>,
    <Menu.Item
      key={2}
      className={location.pathname === "/clients" ? "selected" : ""}>
      <Link className="link-menu" to="/clients">
        <TeamOutlined />
        <span>Mijozlar</span>
      </Link>
    </Menu.Item>,
    <Menu.Item
      key={3}
      className={location.pathname === "/workers" ? "selected" : ""}>
      <Link className="link-menu" to="/workers">
        <IdcardOutlined />
        <span>Ishchilar</span>
      </Link>
    </Menu.Item>,
    <Menu.Item
      key={4}
      className={location.pathname === "/money" ? "selected" : ""}>
      <Link className="link-menu" to="/money">
        <DollarOutlined />
        <span>Pul Hisoboti</span>
      </Link>
    </Menu.Item>,
    <Menu.Item
      key={5}
      className={location.pathname === "/gold" ? "selected" : ""}>
      <Link className="link-menu" to="/gold">
        <GoldOutlined />
        <span>Tilla Hisoboti</span>
      </Link>
    </Menu.Item>,
    <Menu.Item key={6}>
      <Link className="link-menu" to="/">
        <LogoutOutlined />
        <span>Chiqish</span>
      </Link>
    </Menu.Item>,
  ];

  return <Menu mode="inline">{styledTopics}</Menu>;
};
export default TopicMenu;
